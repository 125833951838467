export default [
    {
      path: "/banner/categories",
      name: "banner-category-list",
      component: () =>
        import(
          "@/views/bannercategory/banner-categories-list/BannerCategoriesList.vue"
        ),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/banner/categories/create",
      name: "banner-category-create",
      component: () =>
        import(
          "@/views/bannercategory/banner-categories-edit/BannerCategoriesEdit.vue"
        ),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/banner/categories/edit/:id",
      name: "banner-category-edit",
      component: () =>
        import(
          "@/views/bannercategory/banner-categories-edit/BannerCategoriesEdit.vue"
        ),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/banner/list",
      name: "banner-list",
      component: () => import("@/views/banner/banners-list/BannersList.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/banner/create",
      name: "banner-create",
      component: () => import("@/views/banner/banners-edit/BannersEdit.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    {
      path: "/banner/edit/:id",
      name: "banner-edit",
      component: () => import("@/views/banner/banners-edit/BannersEdit.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
    // {
    //   path: "/banner/detail/:id",
    //   name: "banner-view",
    //   component: () =>
    //     import("@/views/banner/banners-detail/BannersDetail.vue"),
    //   meta: {
    //     action: "read",
    //     resource: "Auth",
    //   },
    // },
]  