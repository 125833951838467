export default [
  {
    path: "/users/list",
    name: "users-list",
    component: () => import("@/views/user/users-list/UsersList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/users/view/:id",
    name: "users-view",
    component: () => import("@/views/user/users-view/UsersView.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "read", // change back to update
      resource: "Auth",
    },
  },
  {
    path: "/users/create",
    name: "users-create",
    component: () => import("@/views/user/users-edit/UsersEdit.vue"),
    meta: {
      action: "read", // change back to create
      resource: "Auth",
    },
  },
];
