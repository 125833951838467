export default [
    // Sticky Bar List
    {
      path: "/stickybar/list",
      name: "stickybar-list",
      component: () =>
        import("@/views/stickybar/stickybar-list/StickyBarList.vue"),
      meta: {
        action: "read",
        resource: "Auth",
      },
    },
];
