export default [
  // {
  //     path: "/category/clients",
  //     name: "clientcategory-list",
  //     component: () => import("@/views/clientaccount/clients-list/ClientsList.vue"),
  //     meta:{
  //         action: "read",
  //         resource: "client_account",
  //     }
  // },
  {
    path: "/clients",
    name: "clients-list",
    component: () => import("@/views/client/clients-list/ClientsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/clients/create",
    name: "clients-create",
    component: () => import("@/views/client/clients-edit/ClientsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/clients/edit/:id",
    name: "clients-edit",
    component: () => import("@/views/client/clients-edit/ClientsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/clients/detail/:id",
    name: "clients-detail",
    component: () => import("@/views/client/clients-detail/ClientsDetail.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
];
