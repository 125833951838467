import { $themeBreakpoints } from "@themeConfig";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
  },
  actions: {
    fetchOptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/options/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDashboardData(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/dashboard")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadDatabaseBackupJSON(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/downloadBackupJSON", { responseType: 'blob' }) // Use GET and set responseType
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadBackupStorage(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/downloadBackupStorage", { responseType: 'blob' }) // Use GET and set responseType
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadDatabaseBackupSQL(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/downloadBackupSQL", { responseType: 'blob' }) // Use GET and set responseType
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAuth(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/auth", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
