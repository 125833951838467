export default [
  {
    path: "/product/categories",
    name: "product-category-list",
    component: () =>
      import(
        "@/views/productcategory/product-categories-list/ProductCategoriesList.vue"
      ),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/categories/create",
    name: "product-category-create",
    component: () =>
      import(
        "@/views/productcategory/product-categories-edit/ProductCategoriesEdit.vue"
      ),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/categories/edit/:id",
    name: "product-category-edit",
    component: () =>
      import(
        "@/views/productcategory/product-categories-edit/ProductCategoriesEdit.vue"
      ),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/list",
    name: "product-list",
    component: () => import("@/views/product/products-list/ProductsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/create",
    name: "product-create",
    component: () => import("@/views/product/products-edit/ProductsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/edit/:id",
    name: "product-edit",
    component: () => import("@/views/product/products-edit/ProductsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/product/detail/:id",
    name: "product-detail",
    component: () =>
      import("@/views/product/products-detail/ProductsDetail.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },

];
