export function getCorrectDateTime(datetime, withoutTime) {
  let utcDate;
  if (datetime.indexOf("Z") > -1) {
    utcDate = new Date(datetime.slice(0, 19));
  } else {
    utcDate = new Date(datetime);
  }

  // Get the timezone offset in minutes
  const timezoneOffset = new Date().getTimezoneOffset();

  // Convert the offset to milliseconds
  const offsetInMs = timezoneOffset * 60 * 1000;

  // Add the offset to the UTC time
  const localTime = new Date(utcDate.getTime() - offsetInMs);

  // Format the local time as a string in the YYYY-MM-DD HH:MM:SS format
  const year = localTime.getFullYear();
  const month = String(localTime.getMonth() + 1).padStart(2, "0");
  const day = String(localTime.getDate()).padStart(2, "0");
  const hours = String(localTime.getHours()).padStart(2, "0");
  const minutes = String(localTime.getMinutes()).padStart(2, "0");
  const seconds = String(localTime.getSeconds()).padStart(2, "0");
  let localTimeString;
  if (withoutTime) {
    localTimeString = `${day}-${month}-${year}`;
  } else {
    localTimeString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  return localTimeString;
}

export const flatDateConfig = {
  altInput: true,
  altFormat: "Y-m-d",
  allowInput: true,
  dateFormat: "Y-m-d",
};

export const flatDateConfigForBirth = {
  altInput: true,
  altFormat: "Y-m-d",
  allowInput: true,
  dateFormat: "Y-m-d",
  maxDate: "today",
};

export const futureDateConfig = {
  altInput: true,
  altFormat: "Y-m-d",
  allowInput: true,
  dateFormat: "Y-m-d",
  minDate: "today",
};
