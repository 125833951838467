export default [
  // Product Vatiants color
  {
    path: "/product/variant/color/list",
    name: "color-list",
    component: () =>
      import("@/views/setting/productvariant/color/color-list/ColorList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },

  // Product Vatiants size
  {
    path: "/product/variant/size/list",
    name: "size-list",
    component: () =>
      import("@/views/setting/productvariant/size/size-list/SizeList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },

  // Product Vatiants type
  {
    path: "/product/variant/type/list",
    name: "type-list",
    component: () =>
      import("@/views/setting/productvariant/type/type-list/TypeList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },

  // Setting
  {
    path: "/settings/settings/edit",
    name: "settings-edit",
    component: () =>
      import("@/views/setting/setting/settings-edit/SettingsEdit.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },

  // Faq
  {
    path: "/faq/list",
    name: "faq-list",
    component: () => import("@/views/faq/faqs-list/FaqsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/faq/create",
    name: "faq-create",
    component: () => import("@/views/faq/faqs-edit/FaqsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/faq/edit/:id",
    name: "faq-edit",
    component: () => import("@/views/faq/faqs-edit/FaqsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
];
