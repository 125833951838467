export default [
    {
      path: "/todo/welcome/staff",
      name: "welcome-staff",
      component: () => import("@/views/todo/welcome_letter/staff/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/welcome/client",
      name: "welcome-client",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/pi",
      name: "pi",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/standing-instruction",
      name: "standing-instruction",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/discretional-renewal",
      name: "discretionary-renewal",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/fatcha-certification",
      name: "fatcha-certification",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/course-compliance",
      name: "course-compliance",
      component: () => import("@/views/todo/welcome_letter/staff/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/annual-return",
      name: "annual-return",
      component: () => import("@/views/todo/welcome_letter/staff/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/annual-compliance",
      name: "annual-compliance",
      component: () => import("@/views/todo/welcome_letter/staff/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/client-birthday",
      name: "client-birthday",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/client-passport-expiry",
      name: "client-passport-expiry",
      component: () => import("@/views/todo/welcome_letter/client/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/user-passport-expiry",
      name: "user-passport-expiry",
      component: () => import("@/views/todo/welcome_letter/staff/staff-list/StaffWelcomeList.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/detail/:id",
      name: "todo-detail",
      component: () => import("@/views/todo/welcome_letter/client/todo-detail/TaskView.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    {
      path: "/todo/details/:id",
      name: "todo-staff-detail",
      component: () => import("@/views/todo/welcome_letter/staff/todo-detail-staff/TaskView.vue"),
      meta: {
        action: "read",
        resource: "actionlist",
      },
    },
    // {
    //   path: '/view-todo',
    //   name: 'todo-tomorrow-details',
    //   component: () => import('@/views/dashboard/dash/dash-details/DashDetail.vue'),
    //   meta: {
    //     action: 'read',
    //     resource: 'actionlist',
    //   },
    // },
  ];
  